//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PaddedText from './components/paddedText.vue';
import ViewportMixin from '@/mixins/viewport.mixin';
import SwiperMixin from '~/mixins/swiper.mixin.js';
import ArrowIcon from '~/assets/svg/ic-arrow-right.svg?inline';
import BlackArrowIcon from '~/assets/svg/ic-arrowRight.svg?inline';
import EventCard from '@/components/events/card.vue';

export default {
  components: { EventCard, PaddedText, ArrowIcon, BlackArrowIcon },
  mixins: [ViewportMixin, SwiperMixin],

  async fetch() {
    let responseWebinars;
    try {
      responseWebinars = await this.$axios({
        method: 'get',
        url: `${this.$app.config.current.CMS_REST_API}/public/v2/events/`,
        params: {
          limit: 6,
          offset: 0,
          directions: [],
          published: true,
        },
      });
    } catch (e) {
      console.error(e);
    }

    let webinars = [];
    if (responseWebinars) {
      if (responseWebinars.data.error) {
        throw new Error(
          `An error occurred while executing the query (${responseWebinars.data.error})`,
        );
      }

      webinars = responseWebinars.data.data.data;
    }

    this.$store.commit('mainPage/setWebinars', Object.assign([], webinars));
  },

  watch: {
    async isMobile(value) {
      await this.$nextTick();

      if (!value) {
        this.initSwiper();
      } else {
        if (this.swiper !== null) {
          this.swiper.destroy();
          this.swiper = null;
        }
      }
    },
  },

  mounted() {
    if (!this.isMobile) {
      this.initSwiper();
    }
  },

  methods: {
    format(date, mask) {
      return this.$dayjs(date).format(mask);
    },
    utcOffset(utcString) {
      return this.$dayjs
        .utc(utcString)
        .utcOffset(this.$app.config.current.utcOffset);
    },
  },
};
