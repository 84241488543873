//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowIcon from '~/assets/svg/ic-arrow-gradient.svg?inline';
import ViewportMixin from '@/mixins/viewport.mixin';

export default {
  components: { ArrowIcon },
  mixins: [ViewportMixin],
  data() {
    return {
      menuActivator: null,
      isScrolled: false,
      isActive: false,
      loading: true,
    };
  },
  computed: {
    coursesActive() {
      const locale = this.$app.config.current.localeName;
      const coursesNamesList = [
        `courses___${locale}`,
        `coursesTopic___${locale}`,
      ];
      return coursesNamesList.includes(this.$route.name);
    },
    aboutUsActive() {
      return (
        this.$route.path === '/about-us' ||
        this.$route.path === '/career-center-students' ||
        this.$route.path === '/career-center-company'
      );
    },
    isBr() {
      return this.$nuxt.$config.COUNTRY === 'br';
    },
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
  },
  mounted() {
    this.menuActivator = this.$refs.menuActivator;
    this.loading = false;

    this.checkScroll();
    window.addEventListener('scroll', this.checkScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScroll);
  },
  methods: {
    open() {
      this.debug({ name: 'open' });
      this.isActive = !this.isActive;

      // this.$refs.partnersButton.classList.remove('header__link-partners--active')
      // this.$refs.partnersMenu.classList.remove('header__partners-menu--active')
    },
    open_partners() {
      this.debug({ name: 'open_partners' });
      this.$refs.partnersButton.classList.toggle(
        'header__link-partners--active',
      );
      this.$refs.partnersMenu.classList.toggle('header__partners-menu--active');
    },
    checkScroll() {
      this.isScrolled = window.scrollY > 0.0;
    },
    async logout() {
      this.debug({ name: 'logout' }, 'logout');
      document.cookie = `a_token=; path=/; domain=${window.location.hostname}; max-age=-1; secure`;
      this.$app.user = null;
      await this.$store.dispatch('user/logoutFromLms');
    },
  },
};
