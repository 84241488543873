//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseImage from '@/components/common/BaseImage.vue';

export default {
  components: {
    BaseImage,
  },
  props: {
    review: {
      type: Object,
    },
  },
  data() {
    return {
      center: false,
      imageConfig: [
        { ext: 'webp', sizes: ['48x48 1x', '96x96 2x'] },
        { ext: 'png', sizes: ['48x48 1x', '96x96 2x'] },
      ],
    };
  },
  async mounted() {
    this.center = this.$refs.top.clientHeight <= 48;
  },
  methods: {
    format(date, mask) {
      return this.$dayjs(date).format(mask);
    },
    utcOffset(utcString) {
      return this.$dayjs
        .utc(utcString)
        .utcOffset(this.$app.config.current.utcOffset);
    },
  },
};
