//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      coursesTotal: 0,
      professionsTotal: 0,
    };
  },

  async fetch() {
    this.$store.commit(
      'courses/setCoursesTotal',
      await this.getCourseTotalCountByType('0'),
    );
    this.$store.commit(
      'courses/setProfessionsTotal',
      await this.getCourseTotalCountByType('1'),
    );

    // Sentry.captureEvent({
    //   message: 'MY TEST ERROR',
    // });
  },

  methods: {
    async getCourseTotalCountByType(type) {
      try {
        this.debug({ name: 'getCourseTotalCountByType' }, 'type = %s', type);
        let response = await this.$axios({
          method: 'GET',
          url: `${this.$app.config.current.CMS_REST_API}/public/v2/showcase/`,
          params: {
            limit: 1,
            offset: 0,
            types: [type],
            country: this.$app?.config?.common?.currentCountry,
          },
        });

        const total = response.data.data.meta.total;

        this.debug({ name: 'getCourseTotalCountByType' }, 'total = %d', total);
        return total;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
