import { render, staticRenderFns } from "./coursesAndProfessions.vue?vue&type=template&id=f7a6d7e2&scoped=true&"
import script from "./coursesAndProfessions.vue?vue&type=script&lang=js&"
export * from "./coursesAndProfessions.vue?vue&type=script&lang=js&"
import style0 from "./coursesAndProfessions.vue?vue&type=style&index=0&id=f7a6d7e2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7a6d7e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EBtn: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/btn/index.vue').default,LazyImg: require('/app/landing_generator/components/lazy-img.vue').default})
